<template>
  <div>
    <b-row>
      <b-col>
        <h3>{{$t('Tools')}}</h3>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="3">
        <b-link to="/marketing-offers/abandoned-cart">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="ShoppingBagIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Abandoned Cart')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/marketing-offers/sms">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="MailIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Marketing SMS')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/marketing-offers/announcement-text">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="AlertOctagonIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Announcement Text')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/marketing-offers/random-names">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="FilterIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Random Names')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/marketing-offers/marketing-notifications-with-criteria">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="TargetIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Marketing Notifications')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/affiliate-marketing">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="LinkIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Affiliate Marketing')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>{{$t('Offers')}}</h3>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="3">
        <b-link to="/coupons">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="TagIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Coupons')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/packages">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="PackageIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Packages')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/daily-deals">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-danger" icon="GiftIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Daily Deals')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <!-- <b-col md="3">
        <b-link to="/orders">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-primary" icon="CopyIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Buy one Get one free')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col> -->
      <b-col md="3">
        <b-link to="/marketing-offers/products-may-you-like">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-danger" icon="HeartIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Products May You Like')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/marketing-offers/quick-deal">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-warning" icon="ZapIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Quick Deal')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <!-- <b-col md="3">
        <b-link to="/orders">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="PlusSquareIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Addtionl')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col> -->

      <b-col md="3">
        <b-link to="/marketing-offers/discount-by-category">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon class="text-success" icon="PercentIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Discount By Category')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/marketing-offers/buy-two-get-one">
          <b-card no-body>
            <b-card-body>
              <p class="text-center text-primary h3 mb-2 mt-2">
                <feather-icon icon="CodesandboxIcon" size="45" />
                <feather-icon icon="CodesandboxIcon" size="45" /> +
                <feather-icon class="text-success" icon="GiftIcon" size="45" />
              </p>
              <h5 class="text-center">
                {{$t('Buy Two Get One')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'Marketing-Offers'
}
</script>
